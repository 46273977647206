'use strict';

window.jQuery = require('jquery');
window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('./accelerator/slider'));
    processInclude(require('./components/header'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/animations'));
    processInclude(require('./components/addToCartGlobal'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/floatLabels'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('./components/toolTip'));
    processInclude(require('./components/popup'));
    processInclude(require('./product/quickView'));
    processInclude(require('./components/notifyme'));
    processInclude(require('./components/instagram'));
    processInclude(require('./components/passwordValidator'));
    window.recaptchaCallback = function (token) {
        $('body').trigger('recaptcha:callback', { data: token });
    };
});

require('./thirdParty/bootstrap');
require('base/components/spinner');
require('./thirdParty/lazysizes');
require('./thirdParty/smartResize');
require('./thirdParty/hoverIntent');
require('./thirdParty/slick');
require('./thirdParty/jquery-dateTimePicker');
require('./animation');
