'use strict';

function enableTooltipHover() {
    if (window.matchMedia('(min-width: 62em)').matches) {
        $('.info-icon, [data-toggle="tooltip"]').on('mouseenter', function () {
            if ($(this).hasClass('tooltip-shown')) {
                $(this).tooltip('hide');
            } else {
                $('.info-icon, [data-toggle="tooltip"]').tooltip('hide'); // Hide other tooltips that might be open
                $(this).tooltip('show');
            }
        }).on('mouseleave', function () {
            $(this).tooltip('hide');
        });
    } else {
        $('.info-icon, [data-toggle="tooltip"]').off('mouseenter mouseleave');
    }
}

module.exports = function () {
    // includes shim to utilize bootstrap tooltips with OOTB info icons
    $('.info-icon, [data-toggle="tooltip"]').each(function () {
        const $toolTip = $(this);
        var tooltipContent = $toolTip.find('.tooltip, .tooltip-content').html();

        $toolTip.attr('title', tooltipContent);

        $toolTip.tooltip({
            container: 'body',
            html: true,
            trigger: 'manual'
        }).on('show.bs.tooltip', function () {
            $toolTip.addClass('tooltip-shown');
        }).on('hide.bs.tooltip', function () {
            $('.info-icon, [data-toggle="tooltip"]').removeClass('tooltip-shown');
        }).on('shown.bs.tooltip', function () {
            setTimeout(() => {
                $toolTip.tooltip('update');
            }, 800);
        });
    });

    $('.info-icon, [data-toggle="tooltip"]').on('click', function () {
        if ($(this).hasClass('tooltip-shown')) {
            $(this).tooltip('hide');
        } else {
            $('.info-icon, [data-toggle="tooltip"]').tooltip('hide'); // Hide other tooltips that might be open
            $(this).tooltip('show');
        }
    });

    enableTooltipHover();

    $('body').on('click', function (e) {
        var target = $(e.target);
        // Dismiss tooltip on click outside
        if (!target.closest('.info-icon').length && !target.closest('[data-toggle="tooltip"]').length) {
            $('.info-icon, [data-toggle="tooltip"]').tooltip('hide');
        }
    });

    $(window).smartresize(function () {
        enableTooltipHover();
    });
};
