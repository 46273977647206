const passwordCustomValidity = function ($input, score) {
    const validityState = $input.validity;
    if (validityState.valueMissing) {
        $input.setCustomValidity($input.dataset.valueMissing);
    } else if (score && score < 3) {
        $input.setCustomValidity($input.dataset.errorMsg);
    } else {
        $input.setCustomValidity('');
    }

    $input.reportValidity();
};

const validatePasswordField = function ($passwordInput, score) {
    const $passwordWrapper = $passwordInput.closest('.form-group');
    if (score >= 3) {
        $passwordInput.classList.remove('is-invalid');
        $passwordWrapper.classList.remove('invalid-field');
    } else {
        $passwordInput.classList.add('is-invalid');
        $passwordWrapper.classList.add('invalid-field');
    }

    passwordCustomValidity($passwordInput, score);
};

const passwordValidationHandler = function () {
    const $passwordInput = this;
    const $passwordWrapper = $passwordInput.closest('.form-group');
    const $passDesc = $passwordWrapper.querySelector('.password-description');
    const passwordCriteria = JSON.parse($passwordInput ? $passwordInput.dataset.passwordCriteria : {});
    const passwordStrengthArr = JSON.parse($passwordInput ? $passwordInput.dataset.passwordStrengthArr : []);
    const inputValue = $passwordInput.value;
    let score = 0;
    let strengthColor = '';
    let strengthText = '';

    for (const [key, value] of Object.entries(passwordCriteria)) {
        const regex = new RegExp(value);
        const $patternElement = $passwordWrapper.querySelector(`.js-pattern-${key}`);

        if (regex.test(inputValue)) {
            $patternElement.classList.remove('invalid');
            $patternElement.classList.add('valid');
            score++;
        } else {
            $patternElement.classList.add('invalid');
            $patternElement.classList.remove('valid');
        }
    }

    switch (true) {
    case score <= 0:
        strengthColor = '';
        strengthText = passwordStrengthArr[0];
        break;

    case score > 0 && score <= 2:
        strengthColor = 'red';
        strengthText = passwordStrengthArr[1];
        break;

    case score <= 3:
        strengthColor = 'orange';
        strengthText = passwordStrengthArr[2];
        break;

    case score <= 4:
        strengthColor = 'green';
        strengthText = passwordStrengthArr[3];
        break;

    default:
        strengthColor = 'darkGreen';
        strengthText = passwordStrengthArr[4];
        break;
    }

    // Show indicator
    $passwordWrapper.querySelector('.password-strength').className = `password-strength strength${score} ${strengthColor}`;
    $passwordInput.setAttribute('score', score);

    validatePasswordField($passwordInput, score);

    // Show strength text
    $passDesc.textContent = strengthText;
};

const passwordValidation = function () {
    const $passwordInput = document.querySelector('.js-password-criteria');
    const $passwordSubmit = $passwordInput.closest('form').querySelector('[type="submit"]');
    // $passwordInput.addEventListener('focus', passwordValidationHandler);
    $passwordInput.addEventListener('keyup', passwordValidationHandler);
    $passwordInput.addEventListener('change', passwordValidationHandler);
    $passwordInput.addEventListener('blur', function () {
        passwordCustomValidity(this, this.getAttribute('score'));
    });

    if ($passwordSubmit) {
        $passwordSubmit.addEventListener('click', function () {
            const $formPasswordInput = this.closest('form').querySelector('.js-password-criteria');
            passwordCustomValidity($formPasswordInput, $formPasswordInput.getAttribute('score'));
        });
    }
};

const showHidePassword = function () {
    document.querySelector('.show-hide-password').addEventListener('click', function () {
        const $showHideButton = this;
        const $passwordWrapper = $showHideButton.closest('.form-group');
        const $passwordInput = $passwordWrapper.querySelector('.js-password-criteria');

        $showHideButton.classList.toggle('show');

        if ($showHideButton.classList.contains('show')) {
            $passwordInput.setAttribute('type', 'text');
        } else {
            $passwordInput.setAttribute('type', 'password');
        }
    });
};

const initializePasswordValidation = function () {
    if (document.querySelector('.custom-password-field')) {
        passwordValidation();
        showHidePassword();
    }
};

module.exports = {
    initializePasswordValidation: initializePasswordValidation
};
