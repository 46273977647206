'use strict';

var updateMiniCart = true;

/*
 * buzz 3d intro
 */
$(document).ready(function () {
    initializeBuzzIntro();
});

// Define a reusable function for initializing the intro animation
function initializeBuzzIntro() {
    var myAtmosphere = document.querySelector('.buzz3d .atmosphere');
    var myLeftClouds = document.querySelector('.buzz3d .clouds.left');
    var myRightClouds = document.querySelector('.buzz3d .clouds.right');
    var myLeftClouds2 = document.querySelector('.buzz3d .clouds.left.second');
    var myRightClouds2 = document.querySelector('.buzz3d .clouds.right.second');
    var myWhiteout = document.querySelector('.buzz3d .whiteout');

    // start if click
    $('.trigger-bb3d-takeover').on('click keydown', function () {
        loadB3dImg();
        myCloudsTween(myLeftClouds, 'left', 6000, 0);
        myCloudsTween(myRightClouds, 'right', 6000, -6000);
        myCloudsTween(myLeftClouds2, 'left', 4000, -5000);
        myCloudsTween(myRightClouds2, 'left', 4000, -6000);
        myWhiteoutTween(myWhiteout);
        myAtmosphereTween(myAtmosphere);
    });

    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);

    var buzzInFlag = urlParams.get('bb3dstart');
    if (buzzInFlag) {
        loadB3dImg();
        myCloudsTween(myLeftClouds, 'left', 6000, 0);
        myCloudsTween(myRightClouds, 'right', 6000, -6000);
        myCloudsTween(myLeftClouds2, 'left', 4000, -5000);
        myCloudsTween(myRightClouds2, 'left', 4000, -6000);
        myWhiteoutTween(myWhiteout);
        myAtmosphereTween(myAtmosphere);
    }

    var buzzOutFlag = urlParams.get('bb3dexit');

    if (buzzOutFlag) {
        loadB3dImg();
        var myBalloon = document.querySelector('.buzz3d .balloon');

        $(myBalloon, myLeftClouds, myRightClouds, myLeftClouds2, myRightClouds2).css('display', 'block');
        $(myLeftClouds).css('left', '0');
        $(myLeftClouds2).css('left', '0');
        $(myRightClouds).css('right', '0');
        $(myRightClouds2).css('right', '0');
        $(myWhiteout).css('display', 'block');

        var windowWidth = $(window).width();

        $(myLeftClouds).animate({ left: -windowWidth, opacity: 0 }, 5000);
        $(myRightClouds).delay(0).animate({ left: windowWidth, opacity: 0 }, 5000);
        $(myLeftClouds2).delay(0).animate({ left: -windowWidth, opacity: 0 }, 5000);
        $(myRightClouds2).delay(0).animate({ left: windowWidth, opacity: 0 }, 5000);
        $(myWhiteout).delay(0).animate({ opacity: 0 }, 5000);

        $(myBalloon).css('display', 'block').animate({ opacity: 1 }, 1000, function () {
            $(this).animate({
                top: '-=650px'  // Move the balloon up by 400px from its initial position
            }, 8000);
        });
        setTimeout(function () {
            $(myWhiteout).css('display', 'none');
            $(myAtmosphere).css('display', 'none');
            if ($('.search:visible').length === 0) {
                return;
            }
            var url = $('.minicart').data('action-url');
            let $minicartItem = $('.minicart [data-num-of-items]');
            let cartfullCount = 0;
            if ($minicartItem && $minicartItem.length > 0) {
                cartfullCount = parseInt($minicartItem.data('num-of-items'));
            }
            var count = parseInt($('.minicart .minicart-quantity').text(), 10) || cartfullCount;

            if (count !== 0 && $('.minicart .popover.show').length === 0) {
                if (!updateMiniCart) {
                    $('.minicart .popover').addClass('show');
                    return;
                }

                $('.minicart .popover').addClass('show');
                $('.minicart .popover').spinner().start();
                $.get(url, function (data) {
                    $('.minicart .popover').empty();
                    $('.minicart .popover').append(data);
                    updateMiniCart = false;
                    $('.minicart-quantity').text($(data).find('.num-of-items-badge').data('num-of-items'));
                    $.spinner().stop();
                });
            }
        }, 5000);
    }
}

// Define the animation function
function myAtmosphereTween(element) {
    if (!element) return;

    // Wrap element in jQuery object for animation
    $(element).css({ opacity: 0, display: 'none' }).animate(
        { opacity: 0.5 },
        {
            duration: 7000,
            easing: 'swing',
            start: function () {
                $(element).css({ display: 'block' });
            },
            complete: function () {
                window.location.href = $('#buzz3d-wrapper').data('url');
            }
        }
    );
}

// Define the animation function
function myCloudsTween(element, direction, duration, delay) {
    if (!element) return;

    var targetPosition = direction === 'left' ? '100%' : '-100%';

    $(element).css({ left: direction === 'left' ? '-100%' : '100%' });

    setTimeout(function () {
        $(element).animate({
            left: targetPosition
        }, {
            duration: duration,
            easing: 'linear',
            queue: false
        });
    }, delay);
}

// Define the animation function
function myWhiteoutTween(element) {
    if (!element) return;

    // Ensure the element is shown and start with opacity 0
    $(element).css({ display: 'block', opacity: 0 }).animate(
        { opacity: 1 }, // Fade in effect
        {
            duration: 5000, // 5 seconds
            easing: 'linear' // Linear easing for smooth fade-in
        }
    );
}

function loadB3dImg() {
    $('.b3dimg').each(function () {
        var mySrc = this.getAttribute('data-src');
        $(this).attr('src', mySrc);
    });
}
