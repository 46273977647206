'use strict';

var base = require('base/components/clientSideValidation');
var dataLayer = window.dataLayer || [];

var regex = {
    postal: {
        us: /^\d{5}(-\d{4})?$/,
        ca: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
        fr: /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
        it: /^([0-9]){5}$/,
        jp: /^([0-9]){3}[-]([0-9]){4}$/,
        cn: /^([0-9]){6}$/,
        gb: /^([A-PR-UWYZ0-9][A-HK-Y0-9][A-HJKS-U0-9]?[ABEHMNPRV-Y0-9]? {1,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$/,
        xx: /^.{4,10}$/
    },
    phone: {
        us: /^[0-9]{10}$/,
        gb: /^[0-9]{11}$/
    }
};

function isValidEmailAddress(footLgEmail) {
    var pattern = /^([\w+\-]+\.)*[\w+\-]+@([\w+\-]+\.)*[\w+\-]+\.[a-zA-Z]{2,4}$/;
    return pattern.test(footLgEmail);
}

function scrollAnimation() {
    if ($('.is-invalid').length) {
        let position = $('.is-invalid').offset().top - 200;
        $('html, body').animate({
            scrollTop: position
        }, 0);
    }
}

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $(this).find('input, select').each(function () {
            if (!this.validity.valid) {
                $(this).trigger('invalid', this.validity);
            }
        });
    }
    return valid;
}

function checkPhoneValidation($form) {
    let siteId = $('body').data('sitecode');
    if ($form !== undefined) {
        let countryCode = $form.find('select[name$="_addressFields_country"]').val();
        if ((countryCode === undefined) && $('.address-form').length > 0) {
            countryCode = $form.find('select[name$="_address_country"]').val();
        } else if ((countryCode === undefined) && $('.add-new-address-form').length > 0) {
            countryCode = $form.find('select[name$="_address_country"]').val();
        }

        if (siteId === 'US' && countryCode === 'US') {
            $('.changePhoneNumber').attr('maxlength', 10);
        } else {
            $('.changePhoneNumber').attr('maxlength', 11);
        }
    }

    $('body').find("input[type='tel']").on('paste input', function () {
        RemoveAlphaChars(this);
    });
}
function RemoveAlphaChars(txt) {
    setTimeout(function () {
        let initVal = $(txt).val();
        let outputVal = initVal.replace(/[^0-9]/g, "");
        if (initVal != outputVal) {
            $(txt).val(outputVal);
        }
    }, 1);
}

/**
 * Validate visible elements. Requires `this` to be set to form object
 * @returns {boolean} - Flag to indicate if form is valid
 */
function customZipAndPhoneValidation() {
    var valid = true;
    var form = $(this);
    var countryCode = form.find('select[name$="_addressFields_country"]').val();
    // Zip Code validation
    $(this).find('input:visible, select:visible, textarea:visible, input[type="tel"], input[type="email"]').each(function () {
        if (this.name.indexOf('_addressFields_postalCode') > -1 || this.name.indexOf('_address_postalCode') > -1) {
            if ($('.address-form').length) {
                countryCode = form.find('select[name$="_address_country"]').val();
            }
            if ($('.add-new-address-form').length) {
                countryCode = form.find('select[name$="address_country"]').val();
            }
            let value = this.value;
            let isValid = false;
            switch (countryCode) {
            case 'US':
                isValid = regex.postal.us.test(value);
                break;
            case 'CA':
                isValid = regex.postal.ca.test(value);
                break;
            case 'FR':
                isValid = regex.postal.fr.test(value);
                break;
            case 'IT':
                isValid = regex.postal.it.test(value);
                break;
            case 'JP':
                isValid = regex.postal.jp.test(value);
                break;
            case 'CN':
                isValid = regex.postal.it.test(value);
                break;
            case 'GB':
                isValid = regex.postal.gb.test(value);
                break;
            default:
                isValid = regex.postal.xx.test(value);
            }
            if (!isValid) {
                valid = false;
                $(this).addClass('is-invalid');
                $(this).parents('.form-group').find('.invalid-feedback').text('Please enter a valid zip code.');
            }
        } else if (!this.validity.valid) {
            valid = false;
            $(this).trigger('invalid', this.validity);
        }
        // Phone number validation
        if (this.name.indexOf('_addressFields_phone') > -1 || this.name.indexOf('_address_phone') > -1 || this.name.indexOf('_contactInfoFields_phone') > -1 || this.name.indexOf('_contactus_phone') > -1 || this.name.indexOf('_corporateEvent_phoneno') > -1) {
            if ($('.address-form').length) {
                countryCode = form.find('select[name$="_address_country"]').val();
            }
            if ($('.add-new-address-form').length) {
                countryCode = form.find('select[name$="address_country"]').val();
            }
            let invalid;
            let siteId = $('body').data('sitecode');
            let value = this.value;
            let isValid = false;
            if (siteId === 'US' && countryCode === 'GB') {
                switch (countryCode) {
                case 'US':
                    isValid = regex.phone.us.test(value);
                    break;
                default:
                    isValid = regex.phone.us.test(value) || regex.phone.gb.test(value);
                }
                invalid = $(this).parents('.form-group').find('.otherCountryErrorMsg').val();
            } else {
                switch (countryCode) {
                case 'US':
                    isValid = regex.phone.us.test(value);
                    break;
                case 'GB':
                    isValid = regex.phone.gb.test(value);
                    break;
                default:
                    isValid = regex.phone.us.test(value) || regex.phone.gb.test(value);
                }
                invalid = $(this).parents('.form-group').find('.invalid-feedback').data('error-msg');
            }
            if (!isValid) {
                valid = false;
                $(this).addClass('is-invalid');
                $(this).parents('.form-group').find('.invalid-feedback').text(invalid);
            }
        } else if (!this.validity.valid) {
            valid = false;
            $(this).trigger('invalid', this.validity);
        }
        if (this.name.indexOf('_contactus_email') > -1 || this.name.indexOf('_orderStatus_trackOrderEmail') > -1 || this.name.indexOf('_corporateEvent_email') > -1 || this.name.indexOf('_coCustomer_email') > -1) {
            let value = this.value.trim();
            if (isValidEmailAddress(value)) {
                valid = true;
            } else {
                valid = false;
                $(this).addClass('is-invalid');
                $(this).parents('.form-group').find('.invalid-feedback').text($(this).attr('data-error-msg'));
            }
        }
        let giftValid = true;
        if (this.name.indexOf('_shipping_shippingAddress_giftMessage') > -1) {
            let invalid = $(this).parents('.form-group').find('.invalid-feedback').data('error-msg');
            let value = this.value;
            if (value === '') {
                giftValid = false;
                $(this).addClass('is-invalid');
                $(this).parents('.form-group').find('.invalid-feedback').text(invalid);
            }
        }
        if (giftValid === false) {
            valid = false;
        }

        if (!valid) {
            scrollAnimation();
        }
    });
    return valid;
}

function EGCformValidation() {
    var valid = true;
    var $form = $(this);
    var $formEmail = $form.find('input[type="email"]');
    let $selectField = $form.find('#price-dropdown');
    let $enterdField = $form.find('#customPrice');
    let invalid = $form.find('.EGC-error-msg').val();
    let invalidEmail = $form.find('.EGC-email-error-msg').val();

    $form.find('input[type="text"], input[type="email"]').each(function () {
        if ($(this).val() === '') {
            $(this).addClass('is-invalid');
            $(this).parents('.form-group').find('.invalid-feedback').text(invalid);
            valid = false;
        } else {
            $(this).removeClass('is-invalid');
            valid = true;
        }
    });

    let enteredAmmount = true;
    let validAmmount = $enterdField.val();

    if (($selectField.val() === '* Choose Amount') && ($enterdField.val() === '')) {
        $enterdField.addClass('is-invalid');
        $enterdField.parents('.form-group').find('.invalid-feedback').text(invalid);
        valid = false;
    } else if (($selectField.val() !== '* Choose Amount') && ($enterdField.val() === '')) {
        $enterdField.removeClass('is-invalid');
        valid = true;
    } else if (($selectField.val() !== '* Choose Amount') && ($enterdField.val() !== '')) {
        if (validAmmount < 10 || validAmmount > 75) {
            enteredAmmount = false;
        }
    } else {
        $enterdField.removeClass('is-invalid');
        valid = true;
    }

    $formEmail.each(function () {
        if (!isValidEmailAddress($(this).val())) {
            $(this).addClass('is-invalid');
            $(this).parents('.form-group').find('.invalid-feedback').text(invalidEmail);
            valid = false;
        } else {
            $(this).removeClass('is-invalid');
            valid = true;
        }
    });

    if (!enteredAmmount) {
        valid = false;
    }

    if (!valid) {
        scrollAnimation();
    }
    return valid;
}

base.customZipAndPhoneValidation = function (form, event) {
    return customZipAndPhoneValidation.call($(form), event || null);
};

base.EGCformValidation = function (form, event) {
    return EGCformValidation.call(form, event || null);
};

base.checkPhoneValidation = function (form) {
    return checkPhoneValidation(form);
};
$(document).ready(function () {
    $('body').find(".shippingCountry, .billingCountry").on('change', function () {
        let form = $(this).closest('form');
        checkPhoneValidation(form);
    });
    $('form input, form textarea, #brickGiftMessage').on('change blur', function () {
        const $formFields = $(this);
        const inputValue = $formFields.val().trim();
        $formFields.val(inputValue);
    });
    $('form input, form select, form textarea, #brickGiftMessage').on('change blur', function (e) {
        return validateForm.call(this, e);
    });
    $('body').find("input[type='tel']").on('input', function () {
        $(this).val($(this).val().replace(/[^0-9]/g, ''));
    });
    $('body').find("input[type='tel']").on('change blur', function () {
        let valid = true; // eslint-disable-line
        let formSelector = $(this).closest('form');
        let form = $(formSelector);
        let siteId = $('body').data('sitecode');
        let invalid;
        let countryCode = form.find('select[name$="_addressFields_country"]').val();
        if ($('.address-form').length) {
            countryCode = form.find('select[name$="_address_country"]').val();
        }
        let value = this.value;
        let isValid = false;
        if (siteId === 'US' && countryCode === 'GB') {
            switch (countryCode) {
            case 'US':
                isValid = regex.phone.us.test(value);
                break;
            default:
                isValid = regex.phone.us.test(value) || regex.phone.gb.test(value);
            }
            invalid = $(this).parents('.form-group').find('.otherCountryErrorMsg').val();
        } else if (siteId === 'GB' && countryCode !== 'GB') {
            switch (countryCode) {
            case 'US':
                isValid = regex.phone.us.test(value);
                break;
            case 'GB':
                isValid = regex.phone.gb.test(value);
                break;
            default:
                isValid = regex.phone.us.test(value) || regex.phone.gb.test(value);
            }
            invalid = $(this).parents('.form-group').find('.otherCountryErrorMsg').val();
        } else {
            switch (countryCode) {
            case 'US':
                isValid = regex.phone.us.test(value);
                break;
            case 'GB':
                isValid = regex.phone.gb.test(value);
                break;
            default:
                isValid = regex.phone.us.test(value) || regex.phone.gb.test(value);
            }
            invalid = $(this).parents('.form-group').find('.invalid-feedback').data('error-msg');
        }
        if (!isValid) {
            isValid = false;
            $(this).addClass('is-invalid');
            $(this).parents('.form-group').find('.invalid-feedback').text(invalid);
        } else {
            $(this).removeClass('is-invalid');
        }
    });
    $('.shippingZipCode, .billingZipCode, #zipCode').on('change blur', function () {
        var valid = true; // eslint-disable-line
        var formSelector = $(this).closest('form');
        var form = $(formSelector);
        const invalid = $(this).parents('.form-group').find('.invalid-feedback').data('error-msg');
        var countryCode = form.find('select[name$="_addressFields_country"]').val();
        if ($('.address-form').length) {
            countryCode = form.find('select[name$="_address_country"]').val();
        }
        var value = this.value;
        var isValid = false;
        switch (countryCode) {
        case 'US':
            isValid = regex.postal.us.test(value);
            break;
        case 'CA':
            isValid = regex.postal.ca.test(value);
            break;
        case 'FR':
            isValid = regex.postal.fr.test(value);
            break;
        case 'IT':
            isValid = regex.postal.it.test(value);
            break;
        case 'JP':
            isValid = regex.postal.jp.test(value);
            break;
        case 'CN':
            isValid = regex.postal.it.test(value);
            break;
        case 'GB':
            isValid = regex.postal.gb.test(value);
            break;
        default:
            isValid = regex.postal.xx.test(value);
        }
        if (!isValid) {
            valid = false;
            $(this).addClass('is-invalid');
            $(this).parents('.form-group').find('.invalid-feedback').text(invalid);
        }
    });
    $('.emailValidation').on('change blur', function () {
        let $this = $(this);
        let formEMail = $this.val();
        let $subscriberEmail = $('#subscriberEmail');
        let $subscriberTerm = $('#subscriberTerms');
        let $productSubscribe = $('#productSubscribe');
        const $parentGroup = $this.parents('.form-group');
        const invalid = $parentGroup.find('.invalid-feedback').data('error-msg');
        const blankInvalid = $parentGroup.find('.invalid-feedback').data('blank-msg');

        if (!isValidEmailAddress(formEMail)) {
            $this.addClass('is-invalid');
            $parentGroup.find('.invalid-feedback').text(invalid);
            $productSubscribe.attr('disabled', true);
        } else {
            $this.removeClass('is-invalid');
            $productSubscribe.attr('disabled', false);
            if ($subscriberTerm.is(':checked')) {
                $productSubscribe.attr('disabled', false);
                $subscriberTerm.click(function () {
                    $productSubscribe.prop("disabled", !$subscriberTerm.prop("checked"));
                });
            }
        }

        if ($subscriberEmail.length) {
            var subscriberEmailval = $subscriberEmail.val();
            if (subscriberEmailval == '') {
                $this.addClass('is-invalid');
                $parentGroup.find('.invalid-feedback').text(blankInvalid);
                $productSubscribe.attr('disabled', true);
            }
        }
    });
    $('body').find("input[type='tel'], input[type='text'], select").on('change blur', function () {
        try {
            let formSelector = $(this).closest('form');
            let $form = $(formSelector);
            let $stepShipping = $('#stepshipping');

            let countryCode = $form.find('select[name$="_addressFields_country"]').val();
            let phone = $form.find('input[name$="_addressFields_phone"]').val();
            let postalCode = $form.find('input[name$="_addressFields_postalCode"]').val();
            let stateCodeUS = $form.find('select[name$="_addressFields_states_stateUS"]').val();
            let stateCodeUK = $form.find('select[name$="_addressFields_states_stateGB"]').val();
            let address1 = $form.find('input[name$="_addressFields_address1"]').val();
            let firstName = $form.find('input[name$="_addressFields_firstName"]').val();
            let lastName = $form.find('input[name$="_addressFields_lastName"]').val();
            let city = $form.find('input[name$="_addressFields_city"]').val();

            if (firstName && lastName && address1 && countryCode && city && postalCode && phone && (stateCodeUS || stateCodeUK)) {
                var $dataLayerShippingObj = $stepShipping.val();
                if ($dataLayerShippingObj) {
                    dataLayer.push(JSON.parse($dataLayerShippingObj));
                }
                $stepShipping.val('');
            }
        } catch (error) {
            window.console.error('\clientSideValidation.js: error in {0}', error);
        }
    });
});

module.exports = base;
