'use strict';

/* global MutationObserver */

const Swiper = require('swiper/swiper-bundle.min.js');

function initProdSlider(carousel) {
    const carouselId = carousel.dataset.componentId;
    var slideShow = 2;
    if ($('.cart-top-product-slider').length) {
        slideShow = 1;
    }

    /* eslint no-unused-vars: ["error", { "varsIgnorePattern": "prodCarousel" }]*/
    const prodCarousel = new Swiper(carousel, {
        direction: 'horizontal',
        loop: false,
        slidesPerView: slideShow,
        spaceBetween: 16,
        centerInsufficientSlides: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            enabled: false,
            clickable: true
        },
        navigation: {
            nextEl: `.swiper-nav-next-${carouselId}`,
            prevEl: `.swiper-nav-prev-${carouselId}`,
            enabled: false
        },
        scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true,
            hide: false
        },
        breakpoints: {
            480: {
                slidesPerView: slideShow,
                spaceBetween: 24
            },
            768: {
                slidesPerView: 3
            },
            1200: {
                slidesPerView: 4,
                spaceBetween: 48
            }
        }
    });
}

function initLateEinstein() {
    const $einsteinCarousels = document.querySelectorAll('.product-slider.einstein'); // vanilla js 'this'

    if ($einsteinCarousels.length > 0) {
        $einsteinCarousels.forEach(function (carousel) {
            initProdSlider(carousel);
        });
    }
}

function initTippyCarousel() {
    // eslint-disable-next-line no-unused-vars
    const tippyCarousel = new Swiper('.header-ribbon-carousel.secondary.swiper', {
        direction: 'horizontal',
        loop: true,
        slidesPerView: 1,
        pagination: {
            enabled: false
        },
        navigation: {
            nextEl: `.swiper-nav-next-tt`,
            prevEl: `.swiper-nav-prev-tt`,
            enabled: true
        },
        scrollbar: {
            hide: false
        },
        autoplay: {
            delay: 4000
        }
    });
}

module.exports = function () {
    const $prodCarousels = document.querySelectorAll('.product-slider'); // vanilla js 'this'

    if ($prodCarousels.length > 0) {
        $prodCarousels.forEach(function (carousel) {
            initProdSlider(carousel);
        });
    }

    const $tippyTopCarousel = document.querySelectorAll('.header-ribbon-carousel.secondary.swiper'); // vanilla js 'this'
    if ($tippyTopCarousel.length > 0) {
        initTippyCarousel();
    }

    const $einsteinSections = $('[id^=cq_recomm_slot-]');

    $einsteinSections.each(function () {
        var observer = new MutationObserver(initLateEinstein);
        var targetNode = $(this)[0];
        observer.observe(targetNode, { childList: true });
    });

    const $activeOffersCarousels = document.querySelectorAll('.activeOffers-slider'); // vanilla js 'this'

    if ($activeOffersCarousels.length > 0) {
        $activeOffersCarousels.forEach(function (carousel) {
            const carouselId = carousel.dataset.componentId;

            /* eslint no-unused-vars: ["error", { "varsIgnorePattern": "prodCarousel" }]*/
            const prodCarousel = new Swiper(carousel, {
                direction: 'horizontal',
                loop: false,
                slidesPerView: 1,
                spaceBetween: 0,
                centerInsufficientSlides: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    enabled: false,
                    clickable: true
                },
                navigation: {
                    nextEl: `.swiper-nav-next-${carouselId}`,
                    prevEl: `.swiper-nav-prev-${carouselId}`,
                    enabled: true
                },
                breakpoints: {
                    480: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                        navigation: {
                            enabled: true
                        }
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                        navigation: {
                            enabled: true
                        }
                    },
                    1200: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                        navigation: {
                            enabled: true
                        }
                    }
                }
            });
        });
    }
};
