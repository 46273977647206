'use strict';

var base = require('../product/base');
var dataLayer = window.dataLayer || [];

const addToCartTile = function ($addToCartBtn) {
    let form = {
        pid: $addToCartBtn.data('pid'),
        isBundle: $addToCartBtn.data('isbundle'),
        isFurryFriend: $addToCartBtn.data('isfriend'),
        setbundlepids: JSON.stringify($addToCartBtn.data('setbundlepids')),
        quantity: 1
    };
    $.ajax({
        url: $addToCartBtn.data('url'),
        method: 'POST',
        headers: {
            'x-queueit-ajaxpageurl': encodeURI(window.location.href)
        },
        data: form,
        success: function (data) {
            $addToCartBtn.parents('.product-tile').find('.add-to-cart-error').addClass('d-none');
            if (!data.error) {
                base.handlePostCartAdd(data);
                $('body').trigger('product:afterAddToCart', data);
                base.miniCartReportingUrl(data.reportingURL);
                try {
                    if (data.isEnabled) {
                        dataLayer.push(JSON.parse(data.dataLayerObj));
                    }
                } catch (error) {
                    window.console.error('\naddToCartGlobal.js: error in {0}', error);
                }
                if ($addToCartBtn.closest('.cart-top-product-slider').length) {
                    window.location.reload();
                }
            } else {
                $addToCartBtn.parents('.product-tile').find('.add-to-cart-error').removeClass('d-none').html(data.result.message);
            }
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    }).done(function (res, text, XHR) {
        if (XHR.getResponseHeader('X-SF-CC-QUEUEIT')) {
            window.location.href = decodeURIComponent(XHR.getResponseHeader('X-SF-CC-QUEUEIT'));
            return;
        }
    });
};

const initializeAddToCart = function () {
    // add to cart customization on PLP
    $(document).on('click', 'button.add-to-cart-tile', function () {
        addToCartTile($(this));
    });
};

module.exports = {
    initializeAddToCart: initializeAddToCart
};
