'use strict';

var debounce = require('lodash/debounce');
var endpoint = $('.suggestions-wrapper').data('url');
var MIN_CHARS = 3;
var UP_KEY = 38;
var DOWN_KEY = 40;


const isMobile = window.matchMedia('(max-width: 991px)');

function changeHandler() {
    if ($('.search-mobile.js-active').length) {
        closeSearchPanel();
    }
}

isMobile.addEventListener('change', changeHandler);

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).closest('.site-search').find('.suggestions-container');
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    $('body').removeClass('modal-open');
    $('.modal-backdrop.search-bd').remove();
    $('header').siblings().attr('aria-hidden', 'false');
}

/**
 * Tear down Suggestions panel
 */
function tearDownSuggestions() {
    $('.header-main-inner input.search-field').val('');
    $('.search-mobile .suggestions').unbind('scroll');
    $('.site-search').removeClass('active');
}

/*
* error alert toaster
*/
function showGiftCardAlert($error, duration) {
    const $giftcardAlert = $('.giftcard-error-alert');
    $giftcardAlert.removeClass('d-none');
    $error.removeClass('d-none');
    setTimeout(function () {
        $giftcardAlert.addClass('d-none');
        $error.addClass('d-none');
    }, duration);
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    var $suggestionsWrapper = getSuggestionsWrapper(this).empty();

    $.spinner().stop();

    if (typeof (response) !== 'object') {
        $suggestionsWrapper
            .addClass('content-filled')
            .append(response).show();

        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        var suggestionsList = $('.suggestions .item');
        if ($(suggestionsList).length) {
            $('.header-main-inner input.search-field').attr('aria-describedby', 'search-result-count').parents('.site-search').addClass('active');
        } else {
            $('.header-main-inner input.search-field').removeAttr('aria-describedby');
        }
    } else {
        $suggestionsWrapper
            .removeClass('content-filled')
            .hide();
        $('.site-search').removeClass('active');
    }
}

const closeSearchPanel = function () {
    $('body').removeClass('search-is-open');
    $('.site-search').removeClass('show-search-panel');
    $('.site-search input').blur();
    clearModals();
    $('.suggestions-container')
        .removeClass('content-filled')
        .empty();
    tearDownSuggestions();
};

const openSearchPanel = function (evt) {
    $('body').addClass('search-is-open');
    $(evt.target)
        .closest('.js-search-wrapper')
        .find('.site-search')
        .addClass('show-search-panel');
};

/**
 * Toggle Search on Mobile
 */
function toggleMobileSearch() {
    $('.open-search-panel').on('click focus', function (evt) {
        openSearchPanel(evt);
    });
    $('body').on('click', function (evt) {
        if (!$(evt.target).closest('.search, .open-search-panel, .suggestions-wrapper, .modal-dialog').length) {
            closeSearchPanel();
        }
    });
    $(document).on('keyup', function (e) {
        if (e.key === "Escape") {
            closeSearchPanel();
        }
    });
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= MIN_CHARS) {
        $.spinner().start();
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: processResponse,
            error: function () {
                $.spinner().stop();
            }
        });
    } else {
        $('.site-search').removeClass('active');
        $('.suggestions-container')
            .removeClass('content-filled')
            .empty();
    }
}

const init = function () {
    $('.header-main-inner input.search-field').each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        const debounceSuggestions = debounce(getSuggestions, 300);
        $(this).on('keyup', function (e) {
            // Capture Down/Up Arrow Key Events
            switch (e.which) {
            case DOWN_KEY:
            case UP_KEY:
                e.preventDefault(); // prevent moving the cursor
                break;
            default:
                debounceSuggestions(this, e);
            }
        });
    });

    toggleMobileSearch();

    $('input.open-search-panel').on('focus', function () {
        $(this).closest('.search-input-wrapper').addClass('focused-input');
    }).on('blur', function () {
        $(this).closest('.search-input-wrapper').removeClass('focused-input');
    });
};

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

const onBalanceCheck = function () {
    $('form.gc-check-balance').submit(function (event) {
        // Prevent the form from submitting normally
        event.preventDefault();
        let form = $(this);
        let url = form.attr('action');// eslint-disable-next-line
        let response = grecaptcha.getResponse();
        if (response.length == 0) {
            const $recaptcha = $('.invalid-captcha-msg');
            showGiftCardAlert($recaptcha, 3000);
        } else {
            // Get the form data
            let formData = {
                gcNum: $("#gc-num").val(),
                gcPin: $("#gc-pin").val()
            };
            let $errorMsg = $('.card-error-message');
            url = appendToUrl(url, formData);

            // Send the Ajax request
            $.ajax({
                type: 'POST',
                url: url,
                data: JSON.stringify(formData),
                success: function (data) {
                    if (data.resultCode !== 'Failed') {
                        $('.gc-balance').removeClass('d-none');
                        let balance = parseFloat(data.balance.value / 100).toFixed(2);
                        $('.gc-current-balance').text(balance);
                    } else {
                        $('.gc-balance').addClass('d-none');
                        showGiftCardAlert($errorMsg, 3000);
                    }
                },
                error: function () {
                    // Handle the error response
                    $('.gc-balance').addClass('d-none');
                    showGiftCardAlert($errorMsg, 3000);
                }
            });
        }
    });
};

const searchEmptyForm = function () {
    $('body').on('submit', '.search-form', function (evt) {
        const $searchForm = $(this);
        if ($searchForm.find('.search-field').val() === undefined ? '' : $searchForm.find('.search-field').val().trim() === '') {
            evt.preventDefault();
            return false;
        }
    });
};

module.exports = {
    init: init,
    onBalanceCheck: onBalanceCheck,
    searchEmptyForm: searchEmptyForm
};
