'use strict';

const builderConfig = function () {
    const configObject = {
        selections: getProductSelections(),
        packagingOption: getDressAndPackOpt('packaging'),
        dressingOption: getDressAndPackOpt('dressing'),
        dressedIn: getDressedIn(),
        birthCertificate: getBirtCertificate(),
        giftMessage: null,
        melco: getMelcoConfig(),
        giftbox: getGiftBox(),
        isHB: false,
        currentStep: "viewSummary"
    };
    if ($('body').hasClass('heartbox') && $('.product-detail') && $('.product-detail').data('prodtype') === 'set') {
        configObject.isHB = true;
    }
    // Sets go to Summary, but single embroidery also get this function yet should go to step 2 like other single friends
    if ($('.product-detail') && $('.product-detail').data('prodtype') === 'optionProduct') {
        configObject.currentStep = 'chooseClothesandShoes';
    }
    if (!configObject.dressingOption.length) {
        configObject.dressingOption = 'dressathome';
    }
    if (!configObject.packagingOption.length) {
        configObject.packagingOption = 'stuffed';
    }
    const $ryvID = $('input#ryvID');
    if ($ryvID.length == 1 && $ryvID.val() !== 'none' && ($('.ryvIDbundle').length || $ryvID.siblings().find('.includeInYourGift').prop('checked'))) {
        configObject.ryvID = $ryvID.val();
    }
    return configObject;
};

function getProductSelections() {
    const $productContainer = $('.product-detail');
    const productType = $productContainer.data('prodtype');
    let selections = $('[data-builderconfig]').data('builderconfig');

    if (productType === 'set') {
        selections = {};
        let $setItems = $('.set-items');
        $setItems.find('.set-item').each(function (i, ele) {
            // only add products which are selected
            if ($(ele).find('input[name="selected-item"]').is(':checked')) {
                let stepID = $(ele).data('department');
                let pid = $(ele).data('pid');
                if (!selections[stepID]) {
                    selections[stepID] = [];
                }
                selections[stepID].push(pid.toString());
            }
        });
        // heartbox PDP
        if ($('div').hasClass('hb-pdp-right')) {
            const $hbel = $('.hb-pdp-right');
            // sound products
            const $hSound = $('input[name="heartbox-option-sound"]');
            if ($hbel.find($hSound).length) {
                if (!selections.chooseSoundsAndScents) {
                    selections.chooseSoundsAndScents = [];
                }
                const hbSound = $hbel.find($hSound).filter(':checked').val();
                if (hbSound && hbSound != 'none') {
                    selections.chooseSoundsAndScents.push(hbSound.toString());
                }
            }
            // scent products
            const $hscent = $('input[name="heartbox-option-scent"]');
            if ($hbel.find($hscent).length) {
                if (!selections.chooseSoundsAndScents) {
                    selections.chooseSoundsAndScents = [];
                }
                const hbScent = $hbel.find($hscent).filter(':checked').val();
                if (hbScent && hbScent != 'none') {
                    selections.chooseSoundsAndScents.push(hbScent.toString());
                }
            }
        }
    }

    return selections;
}

function getBirtCertificate() {
    const $brirthCertData = $('.save-birth-certificate-details');
    let data;
    if ($brirthCertData.length && !$brirthCertData.hasClass('d-none')) {
        data = $brirthCertData.data().birthFormObj;
    }
    return data;
}

function getDressAndPackOpt(currentOption) {
    const $packaging = $('input[name="stuffFull-radio"]');
    const $dressable = $('input[name="dressFull"]');
    if (currentOption === 'packaging' && $packaging.length) {
        return $packaging.filter(':checked').attr('id');
    } else if (currentOption === 'dressing' && $dressable.length) {
        return $dressable.filter(':checked').attr('id');
    }
    return '';
}

function getDressedIn() {
    const $productContainer = $('.product-detail');
    const productType = $productContainer.data('prodtype');
    const dressedIn = [];
    const $dressable = $('input[name="dressFull"]');
    const $dressme = $('input[name="dressMe"]');
    if (productType === 'bundle') {
        const $bundleItems = $productContainer.find('.set-items');
        if ($bundleItems.find($dressable).filter(':checked').attr('id') === "senddressed") {
            $bundleItems.find('.set-item').each(function (ind, ele) {
                if ($(ele).find($dressme).is(':checked')) {
                    dressedIn.push($(ele).data('pid'));
                }
            });
        }
    } else if (productType === 'set') {
        let $setItems = $('.set-items');
        if ($setItems.find($dressable).filter(':checked').attr('id') === "senddressed") {
            $setItems.find('.set-item').each(function (ind, ele) {
                if ($(ele).find('input[name="selected-item"]').is(':checked') && $(ele).find($dressme).is(':checked')) {
                    dressedIn.push($(ele).data('pid'));
                }
            });
        }
    }
    return dressedIn;
}

function getMelcoConfig() {
    const $productContainer = $('.product-detail');
    const $addToCartBtn = $('button.add-to-cart');
    const productType = $productContainer.data('prodtype');

    const melcoObj = {};
    melcoObj.readyToken = {};
    melcoObj.sessionToken = {};
    if ($('.embroidery-option').length && $('.embroidery-option input[name="readyToken"]').val()) {
        if (productType === 'set') {
            const $setItems = $('.product-set-accordion');
            $setItems.find('.set-item').each(function (ind, ele) {
                if ($(ele).data('furry')) {
                    melcoObj.readyToken[$(ele).data('pid')] = $setItems.find('.embroidery-option input[name="readyToken"]').val();
                    melcoObj.sessionToken[$(ele).data('pid')] = $setItems.find('.embroidery-option input[name="sessionToken"]').val();
                    melcoObj.melcoPersonalizationData = $setItems.find('.embroidery-option input[name="melcoPersonalizationData"]').val();
                }
            });
        } else {
            melcoObj.readyToken[$addToCartBtn.data('pid')] = $('.embroidery-option input[name="readyToken"]').val();
            melcoObj.sessionToken[$addToCartBtn.data('pid')] = $('.embroidery-option input[name="sessionToken"]').val();
            melcoObj.melcoPersonalizationData = $('.embroidery-option input[name="melcoPersonalizationData"]').val();
        }
    }
    return melcoObj;
}

function getGiftBox() {
    if ($('.gift-option-radio input:radio[name=giftbox]:checked').length && $('.gift-option-radio input:radio[name=giftbox]:checked').val() != 'standard') {
        return $('.gift-option-radio input:radio[name=giftbox]:checked').val();
    }
    return '';
}

module.exports = builderConfig;
