const updateBirthCertificate = function ($birthCertForm, isReset) {
    let $birthCertModal = $('#createBirthCertificateModal');
    let $birthCertDetails;
    if ($birthCertForm.parents('.cart-birth-cert').length > 0) {
        $birthCertDetails = $birthCertForm.parents('.cart-birth-cert').find('.save-birth-certificate-details');
        $birthCertModal = $birthCertForm.parents('.createBirthCertificateModalCart');
    } else {
        $birthCertDetails = $('.save-birth-certificate-details');
    }

    const birthFormObj = {};
    $birthCertForm.serializeArray().forEach(item => (birthFormObj[`${item.name}`] = item.value));
    let furryfriendbirthmonth = $birthCertForm.find("#month option:selected").data("month-value");

    $birthCertDetails.find('.v-name').text(birthFormObj.furryfriendname);
    $birthCertDetails.find('.v-birthday').text(`${furryfriendbirthmonth} ${birthFormObj.furryfriendbirthday}, ${birthFormObj.furryfriendbirthyear}`);
    $birthCertDetails.find('.v-frieind-for').text(birthFormObj.furryfriendfor);

    // To read the data, use as follow:
    // $('.save-birth-certificate-details').data().birthFormObj;
    if (isReset) {
        $birthCertDetails.addClass('d-none').data('birthFormObj', null);
        $birthCertForm.find('.form-control.is-invalid').removeClass('is-invalid');
    } else {
        $birthCertDetails.removeClass('d-none').data('birthFormObj', birthFormObj);
    }

    $birthCertModal.modal('hide');
};

const init = function () {
    $('body').on('submit', '.birth-cert-form', function (evt) {
        evt.preventDefault();
        const $this = $(this);
        updateBirthCertificate($this);
        // make ajax call from cart page to update builderconfig of lineitem
        if ($this.parents('.cart.cart-page').length > 0) {
            const form = {
                birthCertificateObj: JSON.stringify($(this).parents('.cart-birth-cert').find('.save-birth-certificate-details').data().birthFormObj),
                uuid: $(this).parents('.cart-lineitem').data('uuid')
            };
            $.ajax({
                url: $('.cart-birth-cert').data('submiturl'),
                method: 'post',
                data: form,
                success: function (data) {
                    if (!data.error) {
                        $this.parents('.cart-birth-cert').find('.birth-cert-button').html('Edit Birth Certificate');
                    }
                },
                error: function (err) {
                    console.log(err);
                }
            });
        // make ajax call from BB page to update builderconfig of session
        } else if ($this.parents('.builder-birth-certificate').length > 0) {
            var $parent = $this.parents('.builder-birth-certificate');
            const form = {
                birthCertificateObj: JSON.stringify($parent.find('.save-birth-certificate-details').data().birthFormObj)
            };
            $.ajax({
                url: $parent.data('url'),
                method: 'post',
                data: form,
                success: function (data) {
                    if (!data.error) {
                        $this.parents('.cart-birth-cert').find('.birth-cert-button').html('Edit Birth Certificate');
                    }
                },
                error: function (err) {
                    console.log(err);
                }
            });
        } else {
            $('.birth-cert-button').text('Edit Birth Certificate');
        }
    });

    $('body').on('click', '.reset-cert-modal', function () {
        let $birthCertForm = $(this).parents('.birth-cert-form');
        let $birthCertTitle = $('.builder-birthcert-title');
        let $birthCertBtn = $('.birth-cert-button');
        $birthCertForm[0].reset();
        updateBirthCertificate($birthCertForm, true);
        $birthCertTitle.addClass('d-none');
        $birthCertBtn.show();
    });

    if ($('.builder-birth-certificate').length > 0 && $('.builder-birth-certificate').data('cert') !== null) {
        let birthFormObj = $('.builder-birth-certificate').data('cert');
        let $birthCertTitle = $('.builder-birthcert-title');
        let $birthCertDetails = $('.save-birth-certificate-details');
        let $birthCertForm = $('.birth-cert-form');
        let $birthCertBtn = $('.birth-cert-button');
        $birthCertForm.find('input[type=text], select').each(function () {
            $(this).val(birthFormObj[$(this).attr('name')]);
        });
        let furryfriendbirthmonth = $birthCertForm.find("#month option:selected").data("month-value");
        $birthCertDetails.find('.v-name').text(birthFormObj.furryfriendname);
        $birthCertDetails.find('.v-birthday').text(`${furryfriendbirthmonth} ${birthFormObj.furryfriendbirthday}, ${birthFormObj.furryfriendbirthyear}`);
        $birthCertDetails.find('.v-frieind-for').text(birthFormObj.furryfriendfor);
        $birthCertTitle.removeClass('d-none');
        $birthCertBtn.hide();
        $birthCertDetails.removeClass('d-none').data('birthFormObj', birthFormObj);
    }
};

module.exports = {
    init: init
};
