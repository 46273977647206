'use strict';

function subscriberFormReset() {
    $('.subscription-form').trigger('reset');
    $('#eventSubscribe').attr('disabled', 'disabled');
}

function showNotification(data, duration) {
    const $notification = $('.notification');
    const $notificationMessage = $('.showMessage');
    const $alert = $('.alert');
    $notificationMessage.text(data.msg);
    $notification.removeClass('d-none').addClass('show');
    setTimeout(function () {
        $notification.addClass('d-none').removeClass('show');
        $alert.removeClass('alert-danger alert-success');
    }, duration);
}

function eventSubscribe() {
    $('body').on('click', '#eventSubscribe', function (event) {
        event.preventDefault();
        const $subscribeSignUp = $('.subscription-signup');
        const $form = $('.subscription-form');
        const url = $(this).data('action');
        const $alert = $('.alert');
        const $confirmationMsg = $subscribeSignUp.find('.confirm-wrap');
        const $errorMsg = $subscribeSignUp.find('.error-wrap');
        $.ajax({
            type: 'POST',
            url: url,
            beforeSend: function () {
                $.spinner().start();
            },
            data: $form.serialize(),
            dataType: 'json',
            success: function (data) {
                if (data.success) {
                    // form is validated, we need to submit it to subscribe now
                    var url = $('.subscription-form-submit-url').val();
                    var source = $('.subscription-form-source').val();
                    var brand = $('.footer-form-brand').val();
                    var productid = $('.subscription-form-productid').val();
                    var campaignname = $('.subscription-form-pgid').val();
                    var firstName = $('#eventSubscriberFirstName').val();
                    var lastName = $('#eventSubscriberLastName').val();
                    var Address1 = $('#eventSubscriberAddress1').val();
                    var City = $('#eventSubscriberCity').val();
                    var State = $('#eventSubscriberState').val();
                    var Zip = $('#eventSubscriberZip').val();
                    var Phone = $('#eventSubscriberPhone').val();

                    var obj = {
                        emailAddress: data.email,
                        source: source,
                        brand: brand,
                        productID: productid,
                        campaignName: campaignname
                    };
                    if (firstName) {
                        obj.firstName = firstName;
                    }
                    if (lastName) {
                        obj.lastName = lastName;
                    }
                    if (Address1) {
                        obj.address1 = Address1;
                    }
                    if (City) {
                        obj.city = City;
                    }
                    if (State) {
                        obj.state = State;
                    }
                    if (Zip) {
                        obj.zip = Zip;
                    }
                    if (Phone) {
                        obj.phone = Phone;
                    }

                    $.ajax({
                        url: url,
                        type: 'GET',
                        dataType: 'json',
                        data: obj,
                        success: function (data_b) {
                            if (data_b.success) {
                                subscriberFormReset();
                                $alert.addClass('alert-success');
                                showNotification(data_b, 3000);
                                if ($confirmationMsg.length) {
                                    $subscribeSignUp.find('.start-wrap').fadeOut(function () {
                                        $confirmationMsg.fadeIn();
                                    });
                                }
                            } else {
                                subscriberFormReset();
                                $alert.addClass('alert-danger');
                                showNotification(data_b, 3000);
                                if ($errorMsg.length) {
                                    $subscribeSignUp.find('.start-wrap').fadeOut(function () {
                                        $errorMsg.fadeIn();
                                    });
                                }
                            }
                        },
                        error: function (err) {
                            subscriberFormReset();
                            $alert.addClass('alert-danger');
                            showNotification(err, 3000);
                            if ($errorMsg.length) {
                                $subscribeSignUp.find('.start-wrap').fadeOut(function () {
                                    $errorMsg.fadeIn();
                                });
                            }
                        }
                    });
                } else {
                    subscriberFormReset();
                    $alert.addClass('alert-danger');
                    showNotification(data, 3000);
                    if ($errorMsg.length) {
                        $subscribeSignUp.find('.start-wrap').fadeOut(function () {
                            $errorMsg.fadeIn();
                        });
                    }
                }
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
}

function eventSubscriberTerms() {
    $('#eventSubscriberEmail, #eventSubscriberTerms').on('input change', function () {
        let email = $('#eventSubscriberEmail').val();
        let isChecked = $('#eventSubscriberTerms').is(':checked');

        if (isValidEmail(email) && isChecked) {
            $('#eventSubscribe').prop('disabled', false);
        } else {
            $('#eventSubscribe').prop('disabled', true);
        }
    });
}

function isValidEmail(email) {
    var emailPattern = /^([\w+\-]+\.)*[\w+\-]+@([\w+\-]+\.)*[\w+\-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
}

module.exports = {
    eventSubscribe: eventSubscribe,
    eventSubscriberTerms: eventSubscriberTerms,
    isValidEmail: isValidEmail
};
